import React, { useEffect, useState } from 'react'
import {
  TabbingSwitch,
  FunctionalToggle,
  ToggledCollapsibleBlock,
  FunctionalButton
} from '../../helperComponents/_components'
import {
  createNewIndicatorCalculated,
  fetchEntityData,
  generateNewScenario,
  getCalculatedFromDataAndMode,
  getCalculatedFromEquation,
  updateEntityIncluded,
  updateEquationCalculatedIndicator
} from '../../utils/fetch'
import IndicatorsAndTrendlinesSearch from '../../helperComponents/IndicatorsAndTrendlinesSearch'
import { saveDataToCache } from '../../utils/functions'
import {
  ITrendline,
  ICollapsiblePanelIndicatorsSwitch,
  IEquationPiece,
  IIncludedId,
  IBasicIndicator,
  ICalculatedIndicator,
  ICombinedIndicatorFormulaAlternativeEquationBlocks,
  IGetCalulatedFromDataAndMode,
  IGetCalculatedFromEquation
} from '../../utils/interfaces'
import {
  IIndicatorSetting,
  IIndicatorsSettings,
  CreateSeriesFromDataAndModeType
} from '../interfaces'
import CombineIndicatorFormula from '../../helperComponents/CombineIndicatorFormula'

const CollapsiblePanelIndicatorsSwitch = ({
  indicators,
  chartInstanceRef,
  allIndicators
}: ICollapsiblePanelIndicatorsSwitch) => {
  const [selectedIndicator, setSelectedIndicator] = useState<string>(
    indicators[0]
  )
  // eslint-disable-next-line
  const [allIndicatorsState, setAllIndicatorsState] = useState<
    (ITrendline | IBasicIndicator | ICalculatedIndicator)[]
  >(allIndicators || [])
  const [newCalculatedName, setNewCalculatedName] = useState<string>('')
  const [addedIndicators, setAddedIndicators] = useState<string[]>([])
  const [addedIndicatorsToLine, setAddedIndicatorsToLine] = useState<
    {
      fid: string
      included: string[]
    }[]
  >([])
  const [seriesUnits, setSeriesUnits] =
    useState<CreateSeriesFromDataAndModeType>('absolute')
  const [indicatorsDict, setIndicatorsDict] =
    useState<ICombinedIndicatorFormulaAlternativeEquationBlocks>({})
  const [tabbingSwitchOptions, setTabbingSwitchOptions] = useState([
    {
      fid: indicators[0],
      title:
        allIndicatorsState.find((ind) => ind.fid === indicators[0])?.title || ''
    }
  ] as {
    fid: string
    title: string
  }[])
  const [indicatorsSettings, setIndicatorsSettings] =
    useState<IIndicatorsSettings>({})
  const [newScenarioName, setNewScenarioName] = useState<string>('')

  const getSeries = (chartInstanceRef: any) => {
    try {
      const series = chartInstanceRef.chartInstance.series.filter((s: any) => {
        const name = (s.options ? s.options : { name: '' }).name || ''

        return name.match(/Navigator \d+/) === null
      })

      return series
    } catch (error) {
      console.error('Error getting series from chart', error)
      return []
    }
  }

  const updateCachedGraph = () => {
    // find all the series of the current chart
    const series = getSeries(chartInstanceRef.current as any)
    const strippedSeries = series.map((s: any) => ({
      name: s.options.name,
      data: s.options.data,
      color: s.options.color,
      type: s.options.type,
      dashStyle: s.options.dashStyle,
      lineWidth: s.options.lineWidth,
      yAxis: s.options.yAxis
    }))

    const key = `indicatorPageChart-${window.location.search}` // please note this must be clear up every time indicator is fetched in Indicator page
    return saveDataToCache(key, strippedSeries)
  }

  const addExternalFull = async (fid: string) => {
    // try retrieving from backend anyway
    const result = await fetchEntityData({
      fid,
      type: 'external',
      title: '',
      frequency: ''
    })

    const series = result.series
    const params = result.params[0]
    setAllIndicatorsState((prev) => [...prev, params])

    return { series, params }
  }

  const handleAddIndicator = async (
    indicator: string,
    dontUpdateChart = false
  ) => {
    try {
      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicator
      )
      let series
      // if indicator not found, we assume its external
      if (!matchedIndicator || matchedIndicator.type === 'external') {
        const retrieved = await addExternalFull(indicator)

        series = retrieved.series
        setTabbingSwitchOptions((prev) => [
          ...prev,
          { fid: retrieved.params.fid, title: retrieved.params.title }
        ])
        // in alll other cases, we first check if the function requires an update to the chart and tabs
      } else if (!dontUpdateChart) {
        const { fid, title, type, frequency } = matchedIndicator

        const result = await fetchEntityData({ fid, title, type, frequency })
        series = result.series
        setTabbingSwitchOptions((prev) => [...prev, { fid, title }])
      }

      if (!dontUpdateChart) {
        if (!series) {
          return false
        }

        const name = series.name

        setIndicatorsSettings((prevSettings) => ({
          ...prevSettings,
          [name]: {
            color: '#7cb5ec',
            lineStyle: 'Solid',
            width: 1,
            yAxis: 'left'
          }
        }))
        setAddedIndicators((prevIndicators) => [...prevIndicators, indicator])
        ;(chartInstanceRef.current as any).chartInstance!.addSeries(series)
      }

      if (dontUpdateChart) {
        const matched = addedIndicatorsToLine.find(
          (_) => _.fid === selectedIndicator
        )

        setAddedIndicatorsToLine((prev) => [
          ...prev,
          {
            fid: selectedIndicator,
            included: [...(matched?.included || []), indicator]
          }
        ])
      }

      return dontUpdateChart ? true : updateCachedGraph()
    } catch (error) {
      console.error('Error adding indicator to chart', error)
      return false
    }
  }

  const handleRemoveIndicator = (indicator: string) => {
    try {
      setAddedIndicators((prevIndicators) =>
        prevIndicators.filter((ind) => ind !== indicator)
      )

      const matchedIndicator = allIndicatorsState?.find(
        (ind) => ind.fid === indicator
      )
      if (!matchedIndicator) return false

      const matchedSeries = (
        chartInstanceRef.current as any
      ).chartInstance.series.find(
        (series: any) => series.name === matchedIndicator.title
      )

      if (!matchedSeries) {
        return false
      }

      matchedSeries.remove()

      return updateCachedGraph()
    } catch (error) {
      console.error('Error removing indicator from chart', error)
    }
  }

  const formatChartSeriesByName = (
    name: string,
    propsToChange: {
      color?: string
      name?: string
      type?: string
      dashStyle?: string
      width?: number
      yAxis?: number
    }
  ) => {
    const series = getSeries(chartInstanceRef.current as any)
    const matchedSeries = series.find(
      (series: any) => series.userOptions.name === name
    )

    if (!matchedSeries) {
      return false
    }

    matchedSeries.update({
      ...(propsToChange.color && { color: propsToChange.color }),
      ...(propsToChange.name && { name: propsToChange.name }),
      ...(propsToChange.type && { type: propsToChange.type }),
      ...(propsToChange.dashStyle && {
        dashStyle: propsToChange.dashStyle
      }),
      ...(propsToChange.width && { lineWidth: propsToChange.width }),
      ...(propsToChange.yAxis !== undefined && {
        yAxis: propsToChange.yAxis
      })
    })

    return true
  }
  /**
   *
   * @param action
   * given an action, this function will calculate the series based on the equation.
   * The equation is a string that will be evaluated to calculate the series data combined with another series.
   * The equation will look like a + b, where a and b are series in the order they come from getSeries(chartInstanceRef.current as any)
   * The function calculates the series and updates the chart with the new series.
   * @param equation string
   * The equation to be evaluated, e.g., "a + b", "a - b", "a * b", "a / b".
   * @returns boolean
   */
  // const handleEditSeries = (
  //   action: 'calculate',
  //   name: string,
  //   equation: string
  // ) => {
  //   if (action === 'calculate') {
  //     // // remove the existing calculated series
  //     // const calculatedSeries = (
  //     //   chartInstanceRef.current as any
  //     // ).chartInstance.series.find((series: any) => series.name === name)

  //     // if (calculatedSeries) {
  //     //   calculatedSeries.remove()
  //     // }
  //     const series = getSeries(chartInstanceRef.current as any)
  //     const dataSets = series.map((s: any) => s.options.data)

  //     const combinedDataByTimestamp: any = {}

  //     // Ensure there are enough series for the equation
  //     const seriesVars = equation.match(/[a-zA-Z]/g)
  //     if (!seriesVars || seriesVars.length > dataSets.length) {
  //       console.error('Not enough series for the equation')
  //       return false
  //     }

  //     // Combine series data by timestamp
  //     dataSets.forEach((dataSet: number[][], seriesIndex: number) => {
  //       dataSet.forEach((entry: number[]) => {
  //         const timestamp = entry[0]
  //         const value = entry[1]

  //         if (!combinedDataByTimestamp[timestamp]) {
  //           combinedDataByTimestamp[timestamp] = {}
  //         }

  //         // Assign each series value to a corresponding variable (a, b, c, etc.)
  //         const variable = String.fromCharCode(97 + seriesIndex) // 'a', 'b', 'c', etc.
  //         combinedDataByTimestamp[timestamp][variable] = value
  //       })
  //     })

  //     // Evaluate the equation for each timestamp
  //     const combinedDataArray = Object.entries(combinedDataByTimestamp).map(
  //       ([timestamp, values]: any) => {
  //         // Replace 'a', 'b', etc. in the equation with actual values for the timestamp
  //         let evaluatedEquation = equation
  //         for (const [key, value] of Object.entries(values)) {
  //           evaluatedEquation = evaluatedEquation.replace(
  //             new RegExp(key, 'g'),
  //             (value as any).toString()
  //           )
  //         }

  //         // replace all the spaces in the equation with nothing and all the remaining letters with 0
  //         evaluatedEquation = evaluatedEquation
  //           .replace(/ /g, '')
  //           .replace(/[a-zA-Z]/g, '0')
  //         // Evaluate the equation safely
  //         let result
  //         try {
  //           result = evaluate(evaluatedEquation)
  //         } catch (error) {
  //           console.error('Error evaluating equation:', {
  //             evaluatedEquation,
  //             error
  //           })
  //           return {
  //             timestamp: Number(timestamp),
  //             value: null
  //           }
  //         }

  //         return {
  //           timestamp: Number(timestamp),
  //           value: result
  //         }
  //       }
  //     )

  //     const newSeries = combinedDataArray.map((entry) => [
  //       entry.timestamp,
  //       entry.value
  //     ])

  //     // replace the existing series with the new series
  //     // remove the existing calculated series
  //     const existingSeries = (
  //       chartInstanceRef.current as any
  //     ).chartInstance.series.find((series: any) => series.name === name)

  //     const params = indicatorsSettings[name]

  //     if (existingSeries) {
  //       existingSeries.remove()
  //     }

  //     // Add the new series to the chart
  //     ;(chartInstanceRef.current as any).chartInstance.addSeries({
  //       data: newSeries,
  //       name,
  //       color: params.color,
  //       dashStyle: params.lineStyle,
  //       lineWidth: params.width,
  //       yAxis: params.yAxis === 'left' ? 1 : 0
  //     })

  //     return updateCachedGraph()
  //   }

  //   return false
  // }

  const handleFormatSeries = (name: string, newSettings: IIndicatorSetting) => {
    setIndicatorsSettings((prevSettings) => ({
      ...prevSettings,
      [name]: newSettings
    }))

    const updated = formatChartSeriesByName(name, {
      color: newSettings.color || '#7cb5ec',
      dashStyle: newSettings.lineStyle || 'Solid',
      width: newSettings.width || 1,
      yAxis: newSettings.yAxis === 'left' ? 1 : 0
    })

    if (updated) {
      return updateCachedGraph()
    }

    return false
  }

  const generateSeries = async ({
    propsForModeData,
    propsForEquation
  }: {
    propsForModeData?: IGetCalulatedFromDataAndMode
    propsForEquation?: IGetCalculatedFromEquation
  }) => {
    let newSeries
    if (propsForModeData) {
      newSeries = await getCalculatedFromDataAndMode(propsForModeData)
    }

    if (propsForEquation) {
      newSeries = await getCalculatedFromEquation(propsForEquation)
    }

    if (!newSeries || Object.keys(newSeries).length === 0) {
      return false
    }

    const name = newSeries.name

    // replace the existing series with the new series
    // remove the existing calculated series
    const existingSeries = (
      chartInstanceRef.current as any
    ).chartInstance.series.find((s: any) => s.name === name)

    if (existingSeries) {
      existingSeries.remove()
    }

    // Add the new series to the chart
    ;(chartInstanceRef.current as any).chartInstance.addSeries(newSeries)

    return updateCachedGraph()
  }

  const handleCalculateNewSeriesFromEquation = async (
    equation: IEquationPiece[]
  ) => {
    const name =
      allIndicatorsState.find((ind) => ind.fid === selectedIndicator)?.title ||
      selectedIndicator

    return await generateSeries({
      propsForEquation: {
        equation,
        title: name,
        frequency: 'monthly'
      }
    })
  }

  const handleCalculateNewSeriesFromDataAndMode = async (
    mode: CreateSeriesFromDataAndModeType
  ) => {
    const indicator = allIndicatorsState.find(
      (ind) => ind.fid === selectedIndicator
    )
    const name = indicator?.title || selectedIndicator

    return await generateSeries({
      propsForModeData: {
        type: indicator?.type || 'external',
        title: name,
        fid: selectedIndicator,
        mode
      }
    })
  }

  const handleCreateNewCalculated = async (equation: IEquationPiece[]) => {
    if (
      !newCalculatedName ||
      newCalculatedName.length === 0 ||
      !equation ||
      equation.length === 0
    ) {
      return false
    }
    const fid = await createNewIndicatorCalculated({
      title: newCalculatedName,
      dataMode: 'values',
      computationMode: 'calculated',
      noRefresh: true
    })

    if (!fid) {
      return false
    }

    const updated = await updateEquationCalculatedIndicator({
      indicatorId: fid,
      equation
    })

    if (!updated) {
      return false
    }

    window.switchFunctions.calculated(fid)

    return true
  }

  const handleCreateNewScenario = async (): Promise<string | null> => {
    if (newScenarioName.length === 0) {
      return null
    }

    const fid = await generateNewScenario(
      newScenarioName,
      'My new scenario',
      false,
      true
    )

    return fid || null
  }

  const handleSubmitTrendlinesSelection = async (entityId: string) => {
    const scenarioIndicators = [...addedIndicators, ...indicators]

    const formattedScenarioIndicators: IIncludedId[] = scenarioIndicators
      .map((indicator) => {
        const foundIndicator = allIndicators.find(
          (allIndicator) => allIndicator.fid === indicator
        )

        if (!foundIndicator || !foundIndicator.type) return null

        return {
          fid: indicator,
          type: foundIndicator.type,
          chart: true,
          data: true
        }
      })
      .filter((indicator): indicator is IIncludedId => indicator !== null)

    const res = await updateEntityIncluded({
      entity: {
        entityId,
        entityType: 'scenario'
      },
      ids: formattedScenarioIndicators
    })

    return res
  }

  const handleUpdateScenario = async () => {
    const fid = await handleCreateNewScenario()

    if (fid) {
      return handleSubmitTrendlinesSelection(fid)
    }

    return false
  }

  useEffect(() => {
    if (!allIndicatorsState) return

    const newSettings = tabbingSwitchOptions
      .map((_) => _.fid)
      .reduce((acc, indicator) => {
        const matchedIndicator = allIndicatorsState.find(
          (ind) => ind.fid === indicator
        )

        if (!matchedIndicator) {
          const ind = tabbingSwitchOptions.find((_) => _.fid === indicator)
          return {
            ...acc,
            [ind!.title]: {
              color: '#7cb5ec',
              lineStyle: 'Solid',
              width: 1,
              yAxis: 1
            }
          }
        }

        return {
          ...acc,
          [matchedIndicator.title]: {
            color: '#7cb5ec',
            lineStyle: 'Solid',
            width: 1,
            yAxis: 1
          }
        }
      }, {})

    setIndicatorsSettings(newSettings)
  }, [indicators, addedIndicators])

  useEffect(() => {
    if (!allIndicatorsState) return

    const result: any = {}

    const keys = 'abcdefghijklmnopqrstuvwxyz'.split('')

    const allIndicators: string[] = [
      selectedIndicator,
      addedIndicatorsToLine
        .filter((_) => _.fid === selectedIndicator)
        .map((_) => _.included)
        .flat()
    ].flat()

    allIndicators.forEach((indicator, index) => {
      const matchedIndicator = allIndicatorsState.find(
        (ind) => ind.fid === indicator
      )

      const matched = matchedIndicator?.title || indicator

      result[keys[index]] = {
        fid: indicator,
        title: matched
      }
    })

    setIndicatorsDict(result)
  }, [selectedIndicator, addedIndicatorsToLine])

  return (
    <div className="flex col-12">
      <div className="col-11 p-2 ps-0">
        <IndicatorsAndTrendlinesSearch
          indicators={(allIndicatorsState as ITrendline[]) || []}
          includedIndicators={addedIndicators}
          refreshIndicators={() => {}}
          direction="down"
          hoverMode
          customMessage="Add Lines to Chart"
          addFunction={(indicator) => handleAddIndicator(indicator)}
          removeFunction={(indicator: string) =>
            handleRemoveIndicator(indicator)
          }
          altFunction={async (id: string) => {}}
        />
      </div>
      <div className="flex col-12 p-2">
        <TabbingSwitch
          numberVisible={4}
          className="p-2 col-12"
          tabbingLook
          options={tabbingSwitchOptions.map((option) => ({
            label: option.title,
            active: selectedIndicator === option.fid,
            onClick: () => setSelectedIndicator(option.fid)
          }))}
        />
        <hr />
        <div className="col-12 p-2 collapsible-side-panel-body">
          {tabbingSwitchOptions
            .map((_) => _.fid)
            .map((key: string) =>
              allIndicatorsState?.find((ind) => ind.fid === key)
            )
            .filter((indicator) => indicator !== undefined)
            .map((indicator, index) => (
              <div key={index} className="flex-column space-between col-12">
                {selectedIndicator === indicator!.fid && (
                  <div className="col-12 my-2 gap-3 flex-column">
                    <ToggledCollapsibleBlock
                      title="Change Untis"
                      initialState={true}
                      className="t-small-children"
                    >
                      <select
                        className="mx-1 wider mt-1"
                        value={seriesUnits}
                        onChange={async (e) => {
                          const value = e.target
                            .value as CreateSeriesFromDataAndModeType

                          const res =
                            await handleCalculateNewSeriesFromDataAndMode(value)
                          if (res) {
                            setSeriesUnits(value)
                          }
                        }}
                      >
                        <option value="absolute">Absolute</option>
                        <option value="percentChangeFromYear">
                          Percent Change from Year
                        </option>
                        <option value="changeAndPercentChangeFromYear">
                          Change from Year
                        </option>
                        <option value="monthlyPercentChange">
                          Monthly Percent Change
                        </option>
                        <option value="annualCompoundedRate">
                          Annual Compounded Rate
                        </option>
                        <option value="continuousRate">Continuous Rate</option>
                        <option value="continuousAnnualRate">
                          Continuous Annual Rate
                        </option>
                        <option value="indexScaled">Index Scaled</option>
                      </select>
                    </ToggledCollapsibleBlock>
                    <ToggledCollapsibleBlock
                      title="Format Series"
                      initialState={true}
                      className="t-small-children"
                    >
                      <div className="col-12 t-small">
                        <div className="flex space-between col-12 my-2">
                          <div className="flex col-6 py-2">
                            <label>Color:</label>
                            <input
                              type="color"
                              value={
                                indicatorsSettings[indicator!.title]
                                  ? indicatorsSettings[indicator!.title].color
                                  : '#7cb5ec'
                              }
                              onChange={(e) =>
                                handleFormatSeries(indicator!.title, {
                                  ...indicatorsSettings[indicator!.title],
                                  color: e.target.value
                                })
                              }
                            />
                          </div>
                          <div className="flex col-6 py-2">
                            <label>Line Width:</label>
                            <input
                              type="number"
                              value={
                                indicatorsSettings[indicator!.title]
                                  ? indicatorsSettings[indicator!.title].width
                                  : 2
                              }
                              onChange={(e) =>
                                handleFormatSeries(indicator!.title, {
                                  ...indicatorsSettings[indicator!.title],
                                  width: Number(e.target.value)
                                })
                              }
                              max={15}
                            />
                          </div>
                          <div className="flex col-6 py-2">
                            <label>Line Style:</label>
                            <select
                              value={
                                indicatorsSettings[indicator!.title]
                                  ? indicatorsSettings[indicator!.title]
                                      .lineStyle
                                  : 'Solid'
                              }
                              onChange={(e) =>
                                handleFormatSeries(indicator!.title, {
                                  ...indicatorsSettings[indicator!.title],
                                  lineStyle: e.target.value
                                })
                              }
                            >
                              <option value="Solid">Solid</option>
                              <option value="Dash">Dash</option>
                              <option value="Dot">Dot</option>
                            </select>
                          </div>
                          <div className="flex col-6">
                            <label className="margin-right-1">
                              Preferred Y-Axis:
                            </label>
                            <FunctionalToggle
                              leftTitle="Left"
                              rightTitle="Right"
                              state={
                                indicatorsSettings[indicator!.title]?.yAxis ===
                                'left'
                              }
                              functionToExecute={() => {
                                const currentYAxis =
                                  indicatorsSettings[indicator!.title]?.yAxis
                                const newYAxis =
                                  currentYAxis === 'left' ? 'right' : 'left'

                                handleFormatSeries(indicator!.title, {
                                  ...indicatorsSettings[indicator!.title],
                                  yAxis: newYAxis
                                })
                              }}
                              disabled={false}
                            />
                          </div>
                        </div>
                      </div>
                    </ToggledCollapsibleBlock>
                    <ToggledCollapsibleBlock
                      title="Calculate Series"
                      className=""
                    >
                      <div className="my-2">
                        <IndicatorsAndTrendlinesSearch
                          indicators={
                            (allIndicatorsState as ITrendline[]) || []
                          }
                          customMessage="Add Lines to Equation"
                          includedIndicators={addedIndicatorsToLine
                            .map((_) => _.included)
                            .flat()}
                          refreshIndicators={() => {}}
                          direction="down"
                          hoverMode
                          inline
                          addFunction={(indicator) =>
                            handleAddIndicator(indicator, true)
                          }
                          removeFunction={(indicator: string) =>
                            handleRemoveIndicator(indicator)
                          }
                          altFunction={async (id: string) => {}}
                        />
                      </div>

                      <CombineIndicatorFormula
                        indicators={allIndicatorsState}
                        indicatorId={''}
                        refreshFunction={() => {}}
                        updateEquationFunction={
                          handleCalculateNewSeriesFromEquation
                        }
                        alternativeMode={{
                          mode: 'ab',
                          indicatorsDict
                        }}
                        customFunctionalButton={{
                          functionToExecute: handleCreateNewCalculated,
                          title: 'Save as a new calculated',
                          className: 'primary wider',
                          children: (
                            <input
                              type="text"
                              value={newCalculatedName}
                              onChange={(e) =>
                                setNewCalculatedName(e.target.value)
                              }
                              className="wide p-2"
                              placeholder="New Calculated Name"
                            />
                          )
                        }}
                      >
                        <ToggledCollapsibleBlock
                          title="Supported Equation Syntax Guide"
                          className="info"
                        >
                          <div className="collapsible-side-panel-rules">
                            <h4>Examples of Valid Equations</h4>
                            <ol>
                              <li>
                                <strong>
                                  Complex Expressions with Parentheses:
                                </strong>
                                <ul>
                                  <li>
                                    <code>
                                      &quot;((a + b) * CAGR of 4 (c)) / (OFFSET
                                      of 2 (d) - e)&quot;
                                    </code>
                                  </li>
                                  <li>
                                    <code>
                                      &quot;a + (b - CAGR of 2 (c)) * OFFSET of
                                      5 (d) / e&quot;
                                    </code>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>
                                  Basic Arithmetic with Variables:
                                </strong>
                                <ul>
                                  <li>
                                    <code>&quot;a + b - c * d / e&quot;</code>
                                  </li>
                                  <li>
                                    <code>&quot;A * B + C - D / E&quot;</code>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>Including Numbers:</strong>
                                <ul>
                                  <li>
                                    <code>&quot;5 + a - 3.2 * b&quot;</code>
                                  </li>
                                  <li>
                                    <code>
                                      &quot;-8 + c / 2 + OFFSET of 4 (d)&quot;
                                    </code>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>Using Special Functions:</strong>
                                <ul>
                                  <li>
                                    <code>
                                      &quot;CAGR of 5 (a) + OFFSET of 3 (b) - 2
                                      * c&quot;
                                    </code>
                                  </li>
                                  <li>
                                    <code>
                                      &quot;1 + CAGR of 3.5 (d) - OFFSET of -1
                                      (e)&quot;
                                    </code>
                                  </li>
                                </ul>
                              </li>
                            </ol>

                            <h4>Supported Syntax Elements</h4>

                            <h5>1. Variables</h5>
                            <ul>
                              <li>
                                <strong>Identifiers:</strong> Use single-letter
                                variables from <code>a</code> to <code>h</code>{' '}
                                (case-insensitive).
                              </li>
                            </ul>

                            <h5>2. Numbers</h5>
                            <ul>
                              <li>
                                <strong>Formats:</strong> Support both integer
                                and decimal numbers. Numbers can be positive or
                                negative.
                                <ul>
                                  <li>
                                    <strong>Examples:</strong> <code>5</code>,{' '}
                                    <code>-8</code>, <code>3.14</code>,{' '}
                                    <code>-0.75</code>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <h5>3. Operators</h5>
                            <ul>
                              <li>
                                <strong>Arithmetic Operators:</strong> Use
                                standard mathematical operators for
                                calculations.
                                <ul>
                                  <li>
                                    <strong>Supported Operators:</strong>{' '}
                                    <code>+</code> (addition), <code>-</code>{' '}
                                    (subtraction), <code>*</code>{' '}
                                    (multiplication), <code>/</code> (division)
                                  </li>
                                  <li>
                                    <strong>Parentheses:</strong> Use{' '}
                                    <code>(</code> and <code>)</code> to define
                                    the order of operations and group
                                    expressions.
                                  </li>
                                  <li>
                                    <strong>Examples:</strong> <code>+</code>,{' '}
                                    <code>-</code>, <code>*</code>,{' '}
                                    <code>/</code>, <code>(</code>,{' '}
                                    <code>)</code>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <h5>4. Special Functions</h5>

                            <h6>CAGR (Compound Annual Growth Rate)</h6>
                            <ul>
                              <li>
                                <strong>Syntax:</strong>{' '}
                                <code>
                                  CAGR of &lt;number&gt; (&lt;variable&gt;)
                                </code>
                                <ul>
                                  <li>
                                    <strong>Purpose:</strong> Applies a CAGR
                                    value to the specified variable.
                                  </li>
                                  <li>
                                    <strong>Components:</strong>
                                    <ul>
                                      <li>
                                        <code>&lt;number&gt;</code>: The CAGR
                                        value (integer or decimal).
                                      </li>
                                      <li>
                                        <code>&lt;variable&gt;</code>: A
                                        single-letter variable from{' '}
                                        <code>a</code> to <code>h</code>.
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <strong>Examples:</strong>{' '}
                                    <code>CAGR of 5 (a)</code>,{' '}
                                    <code>CAGR of 3.2 (B)</code>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <h6>OFFSET</h6>
                            <ul>
                              <li>
                                <strong>Syntax:</strong>{' '}
                                <code>
                                  OFFSET of &lt;number&gt; (&lt;variable&gt;)
                                </code>
                                <ul>
                                  <li>
                                    <strong>Purpose:</strong> Applies an offset
                                    value to the specified variable.
                                  </li>
                                  <li>
                                    <strong>Components:</strong>
                                    <ul>
                                      <li>
                                        <code>&lt;number&gt;</code>: The offset
                                        value (integer or decimal).
                                      </li>
                                      <li>
                                        <code>&lt;variable&gt;</code>: A
                                        single-letter variable from{' '}
                                        <code>a</code> to <code>h</code>.
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <strong>Examples:</strong>{' '}
                                    <code>OFFSET of 5 (b)</code>,{' '}
                                    <code>OFFSET of -2.5 (C)</code>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <h4>Formatting Guidelines</h4>
                            <ul>
                              <li>
                                <strong>Spacing:</strong> Equations can have
                                uneven or inconsistent spacing. The parser
                                handles multiple spaces, no spaces, or a mix.
                                <ul>
                                  <li>
                                    <strong>Valid Examples:</strong>
                                    <ul>
                                      <li>
                                        <code>
                                          &quot;1 + a * b / (c - 5)-8 + b + CAGR
                                          of 5 (a) - OFFSET of 5 (b) + 5&quot;
                                        </code>
                                      </li>
                                      <li>
                                        <code>
                                          &quot;1+a*b/(c-5)-8+b+CAGR
                                          of5(a)-OFFSET of5(b)+5&quot;
                                        </code>
                                      </li>
                                      <li>
                                        <code>
                                          &quot;1 + a * b / ( c -5 ) -8 +b +
                                          CAGR of 5 (a) - OFFSET of 5 (b)
                                          +5&quot;
                                        </code>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>Case Sensitivity:</strong> Variable
                                identifiers are case-insensitive. Both uppercase
                                and lowercase letters are accepted.
                                <ul>
                                  <li>
                                    <strong>Examples:</strong> <code>a</code>,{' '}
                                    <code>A</code>, <code>b</code>,{' '}
                                    <code>B</code>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>Order of Elements:</strong> Mix numbers,
                                variables, operators, and special functions in
                                any valid mathematical order.
                                <ul>
                                  <li>
                                    <strong>Example:</strong>{' '}
                                    <code>
                                      &quot;1 + a * (b - CAGR of 5 (c)) / OFFSET
                                      of 3 (d) - 8&quot;
                                    </code>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <strong>Parentheses Usage:</strong> Use
                                parentheses to dictate operation precedence and
                                group expressions involving variables and
                                special functions.
                                <ul>
                                  <li>
                                    <strong>Example:</strong>{' '}
                                    <code>
                                      &quot;(a + b) * CAGR of 2.5 (c) - OFFSET
                                      of 1 (d)&quot;
                                    </code>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </ToggledCollapsibleBlock>
                      </CombineIndicatorFormula>
                    </ToggledCollapsibleBlock>
                    <ToggledCollapsibleBlock
                      title="Convert To Scenario"
                      className="d-none" // hide it for a s econd
                    >
                      <div className="flex col-12 gap-2">
                        <input
                          type="text"
                          value={newScenarioName}
                          onChange={(e) => setNewScenarioName(e.target.value)}
                          placeholder="Scenario Name"
                        />
                        <FunctionalButton
                          className="primary"
                          functionToExecute={handleUpdateScenario}
                          initialButtonState="Submit"
                          iconSaveMode
                          doesReset
                          disabled={newScenarioName.length === 0}
                        />
                      </div>
                    </ToggledCollapsibleBlock>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default CollapsiblePanelIndicatorsSwitch
