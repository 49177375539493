// Import Highcharts and required modules
import Highcharts from 'highcharts/highstock'
import HighchartsAnnotations from 'highcharts/modules/annotations-advanced'
import HighchartsPriceIndicator from 'highcharts/modules/price-indicator'
import HighchartsHeikinAshi from 'highcharts/modules/heikinashi'
import HighchartsHollowCandlestick from 'highcharts/modules/hollowcandlestick'
import HighchartsFullscreen from 'highcharts/modules/full-screen'
import HighchartsStockTools from 'highcharts/modules/stock-tools'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsBoost from 'highcharts/modules/boost'

// Ensure these utility functions are imported or defined
import {
  getAllValuesGroupedByKey,
  convertFetchedDataToHighcharts,
  convertToRelativeValues
} from '../utils/transformingData'
import { retrieveFromCache } from '../utils/functions'

// Initialize Highcharts modules
HighchartsAnnotations(Highcharts)
HighchartsPriceIndicator(Highcharts)
HighchartsFullscreen(Highcharts)
HighchartsStockTools(Highcharts)
HighchartsHeikinAshi(Highcharts)
HighchartsHollowCandlestick(Highcharts)
HighchartsBoost(Highcharts)
HighchartsAccessibility(Highcharts)

function createHighchartsLinearChart({
  chartData,
  chartOptions,
  componentOptions,
  container,
  page,
  scaleType
}) {
  try {
    let chart = null
    // Compute static options
    const staticOptions = {
      boost: {
        useGPUTranslations: true,
        usePreallocated: true
      },
      chart: {
        type: 'line',
        animation: false,
        height:
          (componentOptions && componentOptions.height) ||
          Math.max(window.innerHeight * 0.6, 300),
        allowMutatingData: false
      },
      title: {
        text: chartOptions.title || '',
        style: {
          fontSize: '0.6rem'
        }
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e. %b',
          month: '%b',
          year: '%Y'
        },
        title: {
          text: ''
        },
        labels: {
          format: '{value:%e. %b}',
          style: {
            fontSize: '8px'
          }
        }
      },
      credits: {
        enabled: true,
        text: 'Chartit360',
        href: 'https://www.chartit360.com'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        itemStyle: {
          fontSize: '8px'
        }
      },
      stockTools: {
        gui: {
          enabled: true,
          buttons: {
            zoomIn: {
              enabled: true // Disable Zoom In
            },
            zoomOut: {
              enabled: true // Disable Zoom Out
            },
            resetZoom: {
              enabled: true // Keep Reset Zoom enabled
            },
            select: {
              enabled: true // Keep Select enabled
            },
            indicator: {
              enabled: true // Keep Indicator enabled
            },
            compare: {
              enabled: false // Disable Compare
            },
            save: {
              enabled: true // Keep Save enabled
            },
            fullScreen: {
              enabled: true // Keep Full Screen enabled
            }
          }
        },
        compare: {
          enabled: false // Disable the Compare tool
        }
      },
      exporting: {
        enabled: true,
        filename: `chartit360-${chartOptions.title}`,
        showExportInProgress: true,
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'downloadCSV',
              'downloadXLS',
              'separator',
              'viewData'
            ]
          }
        }
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        height: 20,
        maskFill: 'rgba(0, 0, 0, 0.2)',
        series: {
          type: 'line',
          color: 'rgba(0, 0, 0, 0.5)',
          lineWidth: 1,
          lineColor: 'rgba(0, 0, 0, 0.5)'
        },
        xAxis: {
          labels: {
            style: {
              color: 'black'
            }
          }
        }
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: true,
        inputEnabled: true,
        inputStyle: {
          color: '#039'
        },
        inputPosition: {
          align: 'left',
          x: 30,
          y: 0
        },
        buttonPosition: {
          align: 'left',
          x: 0,
          y: 0
        },
        verticalAlign: 'top',
        buttons: [
          {
            type: 'month',
            count: 6,
            text: '6m'
          },
          {
            type: 'year',
            count: 1,
            text: '1y'
          },
          {
            type: 'year',
            count: 10,
            text: '10y'
          },
          {
            type: 'all',
            text: 'All',
            count: 1
          }
        ],
        dropdown: 'always',
        selected: 4
      },
      accessibility: {
        enabled: true
      },
      tooltip: {
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 300
            },
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }
        ]
      }
    }

    // Function to compute data options
    function computeDataOptions() {
      const inputData = chartData.fetchedData

      if (!inputData || !chartData.filteredKeys.length) {
        return { title: { text: 'No data available' } }
      }

      let data = []
      if (inputData.length > 0) {
        data = getAllValuesGroupedByKey(inputData).data.filter((entry) =>
          chartData.filteredKeys.includes(entry.title)
        )
      } else {
        data = getAllValuesGroupedByKey(inputData).data
      }

      if (data.length === 0) {
        data = getAllValuesGroupedByKey(inputData).data
      }

      if (scaleType === 'relative') {
        data = data.map((entry) => ({
          ...entry,
          values: convertToRelativeValues(entry.values)
        }))
      }

      if (!data || !data.length) {
        return { title: { text: 'No data available' } }
      }

      // Calculate averages for each data series
      const seriesAverages = chartOptions.oneAxis
        ? [0]
        : data.map((entry) => {
            const sum = entry.values
              .filter((value) => value !== null)
              .reduce((acc, value) => Number(acc) + Number(value), 0)

            if (!sum) return 0

            if (isNaN(Number(sum))) return 0

            return (
              Number(sum) /
              entry.values.filter((value) => value !== null).length
            )
          })

      const minAverage = Math.min(...seriesAverages)
      const threshold = 10 // Adjust as needed

      const colors = [
        '#7cb5ec',
        '#434348',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1'
      ]

      // need to check if there is data to be applied to the chart in case it accidentally rerendered
      const key = `indicatorPageChart-${window.location.search}` // please note this must be clear up every time indicator is fetched in Indicator page
      // const saved = saveDataToCache(key, strippedSeries)
      const retrieved = retrieveFromCache(key, 1)

      const basicSeriesSetup = (index) => ({
        type: 'line',
        allowPointSelect: true,
        boostThreshold: 500,
        color: colors[index % colors.length],
        lineWidth: 1,
        marker: {
          enabled: false,
          radius: 1,
          symbol: 'square'
        },
        dashStyle: 'Solid',
        units: [['day', [1]]],
        yAxis:
          scaleType === 'relative' || chartOptions.oneAxis
            ? 0
            : seriesAverages[index] / minAverage > threshold
              ? 1
              : 0
      })

      if (!retrieved.data || page !== 'indicator') {
        const series = data.map((entry, index) => ({
          name: chartData.titles
            ? (chartData.titles.find((title) => {
                const [key] = Object.entries(title)[0]
                return key === entry.title
              }) || { [entry.title]: entry.title })[entry.title]
            : entry.title,
          data: convertFetchedDataToHighcharts(entry),
          ...basicSeriesSetup(index)
        }))

        return { series }
      } else {
        const series = retrieved.data.map((entry, index) => ({
          ...basicSeriesSetup(index),
          ...entry
        }))

        return { series }
      }
    }

    // Function to compute dynamic options
    function computeDynamicOptions() {
      return {
        zooming: {
          mouseWheel: {
            enabled: true,
            type: 'x'
          },
          pinchType:
            chartOptions.mediumSize || chartOptions.fullSize ? undefined : 'x',
          singleTouch: !(chartOptions.mediumSize || chartOptions.fullSize),
          type:
            chartOptions.mediumSize || chartOptions.fullSize ? undefined : 'x'
        },
        yAxis: [
          {
            animation: false,
            type: scaleType !== 'relative' ? scaleType : 'linear',
            title: {
              text: 'Y-axis 1'
            },
            labels: {
              format: '{value}',
              rotation: 270,
              formatter: function () {
                return Number(this.value) > 1000000000
                  ? (Number(this.value) / 1000000000).toFixed(1) + 'b'
                  : Number(this.value) > 1000000
                    ? (Number(this.value) / 1000000).toFixed(1) + 'm'
                    : Number(this.value) > 1000
                      ? (Number(this.value) / 1000).toFixed(1) + 'k'
                      : String(this.value)
              }
            },
            opposite: false
          },
          {
            animation: false,
            type: scaleType !== 'relative' ? scaleType : 'linear',
            title: {
              text: 'Y-axis 2'
            },
            labels: {
              formatter: function () {
                return Number(this.value) > 1000000000
                  ? (Number(this.value) / 1000000000).toFixed(1) + 'b'
                  : Number(this.value) > 1000000
                    ? (Number(this.value) / 1000000).toFixed(1) + 'm'
                    : Number(this.value) > 1000
                      ? (Number(this.value) / 1000).toFixed(1) + 'k'
                      : String(this.value)
              },
              rotation: 90
            },
            opposite: true
          }
        ]
      }
    }

    // Function to update the chart
    function updateChart() {
      const dataOptions = computeDataOptions()
      const dynamicOptions = computeDynamicOptions()

      const options = {
        ...staticOptions,
        ...dynamicOptions,
        ...dataOptions
      }

      if (chart) {
        // Update the chart
        chart.update(options)
      } else {
        // Create the chart
        chart = Highcharts.stockChart(container, options)
      }
    }

    // Initial chart rendering
    updateChart()

    // Return methods to update the chart
    return {
      chartInstance: chart,
      updateChart,
      setScaleType(newScaleType) {
        scaleType = newScaleType
        updateChart()
      }
    }
  } catch (error) {
    console.error('Error rendering chart', error)
    if (container) {
      container.innerHTML = 'Error rendering chart'
    }
  }
}

export default createHighchartsLinearChart
