import React, { useCallback, useEffect, useState } from 'react'
import {
  IAdvancedSearchItem,
  ISearchPage,
  // ITag,
  IThumbnail
} from '../utils/interfaces'
// import { modifyCollectionEntries } from '../utils/fetch'
import iconClose from '../assets/icon-close.png'
import {
  AccessDenied,
  FunctionalButton,
  TabbingSwitch,
  Thumbnails
} from './_components'
import { isEmpty } from '../utils/functions'
import { getAllExternallIndicatorsByName } from '../utils/fetch'

const AdvancedSearch = ({
  scenarios,
  indicators,
  collections,
  forecast,
  trendlines,
  addModeParameters,
  channelsContent,
  memos,
  search,
  disappearing
}: ISearchPage) => {
  try {
    const [searchTerm, setSearchTerm] = React.useState(search || '')
    const [activeButton, setActiveButton] = useState<
      | 'indicator'
      | 'scenario'
      | 'collection'
      | 'forecast'
      | 'all'
      | ''
      | 'calculatedIndicator'
      | 'trendline'
      | 'memo'
      | 'external'
    >('')
    const [filterApplied, setFilterApplied] = useState(false)
    const [thumbnails, setThumbnails] = useState([] as IThumbnail[])
    const allEntities = React.useMemo(() => {
      const allScenarios: IAdvancedSearchItem[] = scenarios
        .map((scenario) => ({
          id: scenario.fid,
          name: scenario.scenario_name,
          details:
            scenario.scenario_overview &&
            scenario.scenario_overview.length > 130
              ? scenario.scenario_overview.slice(0, 130) + '...'
              : scenario.scenario_overview,
          type: 'scenario' as const,
          tags: scenario.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allIndicators: IAdvancedSearchItem[] = indicators
        .filter(
          (indicator) =>
            indicator.type !== 'calculated' &&
            !Object.keys(indicator).includes('base_indicator')
        )
        .map((indicator: any) => ({
          id: indicator.fid,
          name: indicator.title,
          details:
            indicator.short_description &&
            indicator.short_description.length > 130
              ? indicator.short_description.slice(0, 130) + '...'
              : indicator.short_description,
          type: 'indicator' as const,
          tags: indicator.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allForecast: IAdvancedSearchItem[] = forecast
        .filter((forecast) => forecast.type === 'forecast')
        .map((forecast) => ({
          id: forecast.fid,
          name: forecast.title,
          details:
            forecast.short_description &&
            forecast.short_description.length > 130
              ? forecast.short_description.slice(0, 130) + '...'
              : forecast.short_description,
          type: 'forecast' as const,
          tags: forecast.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allCalculatedIndicators: IAdvancedSearchItem[] = indicators
        .filter(
          (indicator) =>
            !allIndicators.map((i) => i.id).includes(indicator.fid) &&
            !Object.keys(indicator).includes('base_indicator')
        )
        .map((indicator: any) => ({
          id: indicator.fid,
          name: indicator.title,
          details:
            indicator.short_description &&
            indicator.short_description.length > 130
              ? indicator.short_description.slice(0, 130) + '...'
              : indicator.short_description,
          type: 'calculated' as const,
          tags: indicator.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allTrendlines: IAdvancedSearchItem[] = trendlines
        .filter(
          (trendline) =>
            trendline.type !== 'calculated' &&
            Object.keys(trendline).includes('base_indicator')
        )
        .map((trendline) => ({
          id: trendline.fid,
          name: trendline.title,
          details:
            trendline.short_description &&
            trendline.short_description.length > 130
              ? trendline.short_description.slice(0, 130) + '...'
              : trendline.short_description,
          type: 'trendline' as const,
          tags: trendline.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allCollections: IAdvancedSearchItem[] = collections
        .filter((collection) =>
          addModeParameters
            ? collection.id !== addModeParameters.collectionId
            : true
        )
        .map((collection) => ({
          id: Number(collection.id),
          name: collection.collection_name,
          type: 'collection' as const,
          tags: collection.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allMemos: IAdvancedSearchItem[] = memos
        .map((memo) => ({
          id: memo.fid,
          name: memo.title,
          type: 'memo' as const,
          tags: memo.tags || []
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const allChannelPieces: IAdvancedSearchItem[] = channelsContent
        .map((channelPiece) => ({
          id: channelPiece.source_fid,
          type: channelPiece.source_type as any,
          name: channelPiece.title,
          tags: channelPiece.tags || [],
          ownerInfo: {
            ...channelPiece.owner_info,
            onClick: () =>
              window.switchFunctions.channel(channelPiece.channel_id as string)
          }
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      return [
        ...allCollections,
        ...allScenarios,
        ...allIndicators,
        ...allCalculatedIndicators,
        ...allTrendlines,
        ...allForecast,
        ...allChannelPieces,
        ...allMemos
      ].map((entity) => ({
        ...entity,
        tags:
          Array.isArray(entity.tags) &&
          !isEmpty(entity.tags as any) &&
          entity.tags.length > 0
            ? entity.tags
            : [],
        ownerInfo: Object.keys(entity).includes('ownerInfo')
          ? (entity as any).ownerInfo
          : null
      }))
    }, [
      scenarios,
      indicators,
      collections,
      trendlines,
      forecast,
      channelsContent
    ])

    // const results = useMemo(() => {
    //   const filterOnType = (entity: IAdvancedSearchItem) =>
    //     activeButton === 'all' ||
    //     activeButton === '' ||
    //     entity.type === activeButton

    //   const filterOnSearchTerm = (entity: IAdvancedSearchItem) =>
    //     entity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     (entity.details &&
    //       entity.details.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (entity.tags &&
    //       entity.tags.some((tag) =>
    //         tag.title.toLowerCase().includes(searchTerm.toLowerCase())
    //       ))
    //   if (searchTerm === '' || searchTerm.toLowerCase() === 'all') {
    //     return allEntities.filter(filterOnType)
    //   } else {
    //     return allEntities.filter(
    //       (entity) => filterOnType(entity) && filterOnSearchTerm(entity)
    //     )
    //   }
    // }, [searchTerm, allEntities, activeButton])

    const generateThumbnails = useCallback(
      async (searchExternal = false) => {
        let filteredEntities = allEntities.filter((entity) => {
          const matchesType =
            activeButton === 'all' ||
            activeButton === '' ||
            entity.type === activeButton
          const matchesSearchTerm =
            searchTerm === '' ||
            searchTerm.toLowerCase() === 'all' ||
            entity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (entity.details &&
              entity.details
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (entity.tags &&
              entity.tags.some((tag) =>
                tag.title.toLowerCase().includes(searchTerm.toLowerCase())
              ))

          return matchesType && matchesSearchTerm
        })

        if (
          activeButton === 'external' &&
          searchTerm !== '' &&
          searchExternal
        ) {
          const externals = await getAllExternallIndicatorsByName(searchTerm)
          filteredEntities = [
            ...filteredEntities,
            ...externals.map((external: any) => ({
              id: external.fid,
              name: external.title,
              details:
                external.short_description &&
                external.short_description.length > 130
                  ? external.short_description.slice(0, 130) + '...'
                  : external.short_description,
              type: 'external' as const,
              tags: external.tags || [],
              ownerInfo: null
            }))
          ]
        }

        return filteredEntities.map((entity) => ({
          image: '',
          title: entity.name,
          subtitle: entity.type,
          tags: entity.tags,
          details: entity.details || '',
          onClick: () =>
            window.switchFunctions[entity.type](entity.id as string),
          addNewMode: false,
          ownerInfo: entity.ownerInfo
        }))
      },
      [allEntities, activeButton, searchTerm]
    )

    const handleActiveButtonToggle = useCallback(
      (
        type:
          | 'indicator'
          | 'scenario'
          | 'collection'
          | 'forecast'
          | 'all'
          | ''
          | 'memo'
          | 'calculatedIndicator'
          | 'trendline'
          | 'external'
      ) => {
        if (activeButton !== type) {
          setFilterApplied(true)
          setActiveButton(type)
        } else {
          setFilterApplied(true)
        }
      },
      []
    )

    const handleGenerateThumbnails = async () => {
      const all = await generateThumbnails()
      setThumbnails(all)
    }

    const searchAll = async () => {
      const all = await generateThumbnails(true)
      setThumbnails(all)

      return all.length > 0
    }

    useEffect(() => {
      handleGenerateThumbnails()
    }, [searchTerm, allEntities, activeButton])

    return (
      <div className="advanced-search">
        <div
          className="col-12"
          style={{
            position: 'relative'
          }}
        >
          <input
            type="text"
            value={searchTerm}
            className={'plat-search-input'}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm.length > 0 && (
            <button
              className="inline no-btn search-reset no-hover"
              onClick={() => {
                setSearchTerm('')
              }}
            >
              <img src={iconClose} alt="" className="icon inline" />
            </button>
          )}
        </div>
        <div className="inline flex col-12 gap-2 justify-content-start p-2">
          <TabbingSwitch
            options={[
              'all',
              'indicator',
              'calculated',
              'scenario',
              'external',
              'trendline',
              'forecast',
              'memo',
              'collection'
            ].map((option) => ({
              label: option.charAt(0).toUpperCase() + option.slice(1),
              onClick: () => handleActiveButtonToggle(option as any),
              active: activeButton === option,
              className: 'wide'
            }))}
            fit
            numberVisible={6}
            disappearing={disappearing}
          />
        </div>
        <div className="results default-text">
          {/*         {searchTerm !== '' && filterApplied && results.length === 0 && (
          <h3>No Results found for {searchTerm}</h3>
        )}
        {searchTerm === '' && !filterApplied && (
          <h3 className="col-12 center middle default-text">
            Start typing to search
          </h3>
        )} */}
          {searchTerm !== '' || filterApplied
            ? thumbnails.length > 0 && (
                <Thumbnails thumbnails={thumbnails} itemsInRow={5} />
              )
            : null}
          {thumbnails.length === 0 && activeButton === 'external' && (
            <div className="flex col-12 center middle p-1">
              <FunctionalButton
                functionToExecute={searchAll}
                initialButtonState="Search External"
                disabled={searchTerm === ''}
                className="primary widest"
                doesReset
              />
            </div>
          )}
        </div>
      </div>
    )
  } catch (err) {
    console.error(err)
    return <AccessDenied />
  }
}

export default AdvancedSearch
