import { S3Client } from '@aws-sdk/client-s3'
import { createPresignedPost } from '@aws-sdk/s3-presigned-post'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from './functions'
import OpenAI from 'openai'
import React from 'react'

import {
  IMemoEntry,
  ICloneIndicator,
  ICreateNewIndicator,
  IHomepageSection,
  IUpdateDbIndicatorData,
  IUserSettings,
  LoginFormData,
  RegisterFormData,
  IChatMessage,
  IEquationPiece,
  IScenarioItem,
  IIncludedId,
  ITag,
  ITrendline,
  IBasicIndicator,
  IGetCalculatedFromEquation,
  IGetCalulatedFromDataAndMode
} from './interfaces'

import axios from 'axios'
import {
  findClosestMidnightString,
  transformFREDIndicatorsToPlatformIndicators
} from './transformingData'

const HEADERS = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
  'Workspace-Fid': localStorage.getItem('workspaceFid') || '',
  Referer: 'https://www.chartit360.com'
}

const URL =
  process.env.NODE_ENV === 'test'
    ? 'https://staging-chartit-360-f78c8a8a2c22.herokuapp.com'
    : process.env.NODE_ENV === 'development'
      ? 'http://localhost:5423'
      : 'https://35.178.24.253'
// : 'https://chartit-360.herokuapp.com'
// : 'http://18.175.200.158/'
// 'https://ec2-35-178-24-253.eu-west-2.compute.amazonaws.com/'

const addComparison = async ({
  entityType,
  entityId,
  content,
  comparisonName
}: {
  entityType: string
  entityId: string
  content: string
  comparisonName: string
}) => {
  const response = await fetch(`${URL}/add_comparison`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      entityType,
      entityId,
      content,
      comparisonName
    })
  })
  return response.ok
}

const checkIsAdmin = async () => {
  const response = await fetch(`${URL}/auth/admin`, {
    method: 'GET',
    headers: HEADERS
  })

  const result = await response.json()

  return result
}

const runAdminQuery = async (query: string, visual: boolean) => {
  const response = await fetch(`${URL}/auth/admin/run_query`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      query,
      visual
    })
  })

  const result = await response.json()

  return result
}

const updateIndicatorCalculatedComputationalMode = async ({
  indicatorId,
  newMode,
  dataMode
}: {
  indicatorId: string
  newMode: string
  dataMode: string
}) => {
  const res = await fetch(`${URL}/indicator/computation_mode`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId,
      newMode,
      dataMode
    })
  })

  return res.ok
}

const addGPTChat = async ({
  userId,
  content,
  title
}: {
  userId: string
  content: string
  title: string
}) => {
  const response = await fetch(`${URL}/add_gpt_chat`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      content,
      title
    })
  })
  return response.ok
}

const initialFetch = async () => {
  const response = await fetch(`${URL}/initial_fetch`, {
    method: 'GET',
    headers: HEADERS
  })

  return response.json()
}

const extraHomepageFetch = async () => {
  const response = await fetch(`${URL}/initial_fetch/homepage_extra_data`, {
    method: 'GET',
    headers: HEADERS
  })

  return response.json()
}

const homepageFetch = async () => {
  const response = await fetch(`${URL}/homepage`, {
    method: 'GET',
    headers: HEADERS
  })

  return response.json()
}

const fetchFullIndicatorsParameters = async (userId: string) => {
  const response = await fetch(
    `${URL}/indicators/parameters?userId=${userId}`,
    {
      method: 'GET',
      headers: HEADERS
    }
  )
  return response.json()
}

const getAPIData = async (request: string) => {
  const response = await fetch(`${URL}/api/fred`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      request
    })
  })

  return response.json()
}

const modifyCollectionEntries = async ({
  collectionId,
  action,
  entityId,
  entityType,
  entityName
}: {
  collectionId: number
  action: 'add' | 'remove'
  entityId: string
  entityName: string
  entityType:
    | 'scenario'
    | 'indicator'
    | 'collection'
    | 'calculated'
    | 'memo'
    | 'trendline'
    | 'forecast'
    | 'external'
}) => {
  const response = await fetch(`${URL}/collection/update_collection_content`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      collectionId,
      content: {
        action,
        entityId,
        entityType,
        entityName
      }
    })
  })

  return response.ok
}

const updateSelectedColumnsEntity = async ({
  selectedChartList,
  selectedDataList,
  entityId,
  entityType
}: {
  selectedChartList: string
  selectedDataList: string
  entityId: string
  entityType: 'scenario' | 'indicator' | 'calculated'
}) => {
  try {
    const res = await fetch(`${URL}/entity/trendlines/selected`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        selectedChartList,
        selectedDataList,
        entity: {
          entityType,
          entityId
        }
      })
    })

    return res.ok
  } catch (err) {
    console.error(err)
    return false
  }
}

const updateEntityTrendlineIncluded = async ({
  entity,
  id,
  action
}: {
  entity: {
    entityId: any
    entityType: 'scenario' | 'calculated'
  }
  id: string
  action: 'add' | 'remove'
}) => {
  try {
    const result = await fetch(`${URL}/entity/trendline/included`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        entity,
        id,
        action
      })
    })

    return result.ok
  } catch (err) {
    console.error(err)
    return false
  }
}

const updateEntityIncluded = async ({
  entity,
  ids
}: {
  entity: {
    entityId: any
    entityType: 'scenario' | 'calculated'
  }
  ids: IIncludedId[]
}) => {
  try {
    const result = await fetch(`${URL}/entity/included`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        entityId: entity.entityId,
        ids,
        entityType: entity.entityType
      })
    })

    return result.ok
  } catch (err) {
    console.error(err)
    return false
  }
}

const updateEntityTrendlineParametersMultiple = async ({
  entity,
  addList,
  removeList,
  includedList
}: {
  entity: {
    entityId: any
    entityType: 'scenario' | 'calculated'
  }
  addList: string[]
  removeList: string[]
  includedList: string[]
}) => {
  try {
    const result = await fetch(`${URL}/entity/trendline/included/multiple`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        entity,
        addList,
        removeList,
        includedList
      })
    })

    return result.ok
  } catch (err) {
    console.error(err)
    return false
  }
}

const createNewIndicator = async ({
  indicatorName,
  indicatorMeaning,
  isInversed,
  indicatorCategory,
  data,
  isCore
}: ICreateNewIndicator) => {
  try {
    const res = await fetch(`${URL}/create_new_indicator`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({
        indicatorName,
        indicatorMeaning: indicatorMeaning || '',
        isInversed,
        indicatorCategory,
        data,
        isCore
      })
    })

    const json = await res.json()

    if (res.ok) {
      window.switchFunctions.indicator(json)
    }

    return res.ok
  } catch (err) {
    return false
  }
}

const updateDbIndicator = async ({
  indicatorName,
  data,
  replace
}: IUpdateDbIndicatorData) => {
  try {
    const res = await fetch(`${URL}/indicator/update_indicator_data`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        indicatorName,
        data,
        replace
      })
    })
    return res.ok
  } catch (err) {
    return false
  }
}

const handleFormSubmitLogin = async ({
  formData
}: {
  formData: LoginFormData
}) => {
  try {
    const result = await fetch(`${URL}/api/auth/login`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(formData)
    })
    const data = await result.json()
    localStorage.setItem('token', data.token)
    localStorage.setItem('userId', data.userId)
    localStorage.setItem('workspaceFid', data.workspaceFid)

    if (data && data.token) {
      window.location.href = '/app'
      return true
    }
    return false
  } catch (err: any) {
    console.error(err.response.data.message)
    return false
  }
}

const handleFormSubmitRegister = async (
  e: React.FormEvent<HTMLFormElement>,
  { formData }: { formData: RegisterFormData }
) => {
  e.preventDefault()
  if (formData.password !== formData.confirmPassword) {
    console.error('Passwords do not match')
    return
  }
  try {
    const res = await fetch(`${URL}/api/auth/register`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(formData)
    })
    const data = await res.json()
    if (data) {
      window.location.href = '/login'
    }
  } catch (err: any) {
    console.error(err.response.data.message)
  }
}

const updateMemo = async ({
  fid,
  memoName,
  memoContent,
  shortDescription,
  description
}: {
  fid: string
  memoName?: string
  memoContent?: IMemoEntry[]
  shortDescription?: string
  description?: string
}) => {
  try {
    const res = await fetch(`${URL}/memo`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        fid,
        memoName,
        memoContent,
        shortDescription,
        description
      })
    })
    return res.ok
  } catch (err) {
    return false
  }
}

const deleteMemo = async (fid: string) => {
  try {
    const res = await fetch(`${URL}/memo`, {
      method: 'DELETE',
      headers: HEADERS,
      body: JSON.stringify({
        fid
      })
    })

    return res.ok
  } catch (err) {
    return false
  }
}

const cloneIndicator = async ({
  existingIndcatorTableName,
  newIndicatorName,
  isTracking
}: ICloneIndicator) => {
  try {
    const res = await fetch(`${URL}/clone_existing_indicator`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({
        existingIndcatorTableName,
        newIndicatorName,
        isTracking
      })
    })
    const json = await res.json()
    if (res.ok) {
      window.switchFunctions.indicator(json)
    }

    return res.ok
  } catch (err) {
    return false
  }
}

const checkEntityAccess = async ({
  entityId,
  entityType
}: {
  entityId: string
  entityType: 'scenario' | 'indicator'
}) => {
  try {
    const res = await fetch(`${URL}/entity/check_access`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({
        entityId,
        entityType
      })
    })
    return res.json()
  } catch (err) {
    return false
  }
}

const getMemoLink = async (file: File, imageType: string) => {
  const res = await fetch(`${URL}/memo/link`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      imageType
    })
  })

  const blob = new Blob([file], { type: file.type })

  const json = await res.json()
  const { fields, url, link } = json

  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value as string)
  })
  formData.append('file', blob, 'file.png')

  const result = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  if (result.status === 204) {
    return link
  }

  return false
}

const getImageFromS3 = async (imageKey: string) => {
  const response = await fetch(`${URL}/memo/fetch-image`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      imageKey
    })
  })

  const json = await response.json()

  return json
}

const fetchChosenScenario = async (fid: string) => {
  const res = await fetch(`${URL}/fetch_scenario?fid=${fid}`, {
    method: 'GET',
    headers: HEADERS
  })

  if (!res.ok) {
    window.switchFunctions.reset()
    return {}
  }

  const result = await res.json()
  const {
    indicatorsData,
    indicatorsParameters,
    scenario,
    comparisons,
    collections,
    snapshots,
    fullDates,
    trendlinesParameters,
    allIndicators
  } = result

  if (!scenario || scenario === undefined) {
    window.switchFunctions.reset()
    return {}
  }

  return {
    fetchedScenarioData: indicatorsData,
    fetchedAllIndicators: indicatorsParameters,
    fetchedScenarioDetails: scenario as IScenarioItem,
    fetchedSavedComparisonsData: comparisons,
    fetchedCollections: collections,
    fetchedPDFSnapshots: snapshots,
    completeDates: fullDates,
    fetchedTrendlinesParameters: trendlinesParameters,
    allIndicators
  }
}

const fetchHomepage = async () => {
  const res = await fetch(`${URL}/homepage`, {
    method: 'GET',
    headers: HEADERS
  })
  const result = await res.json()
  const { indicatorsData, indicatorsParameters, collections, fullDates } =
    result

  return {
    fetchedScenarioData: indicatorsData,
    fetchedAllIndicators: indicatorsParameters,
    fetchedCollections: collections,
    completeDates: fullDates
  }
}

const fetchScenarioDetails = async (fid: string) => {
  const res = await fetch(`${URL}/scenario/details`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  return res.json()
}

const getAllExternallIndicatorsByName = async (searchTerm: string) => {
  const res = await getAPIData(`fred/series/search?search_text=${searchTerm}`)
  const dataConverted = transformFREDIndicatorsToPlatformIndicators(res.seriess)

  return (
    dataConverted.filter((external) => external !== null) as IBasicIndicator[]
  ).filter(
    (indicator, index, self) =>
      index ===
      self.findIndex(
        (t) => t.title.toLowerCase() === indicator.title.toLowerCase()
      )
  )
}

const dummyFunction = async () => {
  const res = await fetch(`${URL}/dummy_function_3`, {
    method: 'POST',
    headers: HEADERS
  })
  return res.json()
}

const fetchChosenIndicator = async (indicatorId: string) => {
  const res = await fetch(`${URL}/indicator`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId
    })
  })

  const {
    // indicatorData,
    indicatorDataSlicedByRange,
    indicatorParameters,
    collections,
    fullDates,
    trendlines,
    allIndicators,
    forecasts,
    variations,
    owner
  } = await res.json()

  return {
    fetchedIndicatorData: indicatorDataSlicedByRange,
    fetchedIndicatorParameters: indicatorParameters,
    collections,
    fullDates,
    trendlines,
    allIndicators,
    forecasts,
    variations,
    owner
  }
}

const fetchExtraData = async (
  parameters: (ITrendline | IBasicIndicator)[],
  frequency: string
) => {
  const res = await fetch(`${URL}/extra_data`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      parameters,
      frequency
    })
  })

  return res.json()
}

const convertExternalToPlatformIndicator = async (externalId: string) => {
  const res = await fetch(`${URL}/external/convert`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      externalId
    })
  })

  const result = await res.json()

  if (res.ok && result) {
    window.switchFunctions.indicator(result.fid)
    return true
  }

  return false
}

const refreshExternalData = async (externalId: string) => {
  const res = await fetch(`${URL}/external/refresh`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      externalId
    })
  })

  return res.ok
}

const fetchEntityData = async ({
  fid,
  type,
  frequency,
  title
}: {
  fid: string
  type: string
  frequency: string
  title: string
}) => {
  const res = await fetch(`${URL}/entity/data`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      type,
      frequency,
      title
    })
  })

  return res.json()
}

// THIS FUNCTION IS A PROBLEM FOR PRODUCTION
const getFilesIds = async (uploadedFiles: any) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY as string,
    dangerouslyAllowBrowser: true
  })

  if (!uploadedFiles) {
    return []
  }
  const resultingIds: string[] = []

  const promises = uploadedFiles.map(async (file: any) => {
    const fileUploaded = await openai.files.create(
      {
        file,
        purpose: 'assistants'
      },
      {
        timeout: 1000 * 60 * 10
      }
    )

    resultingIds.push(fileUploaded.id as string)
    return fileUploaded.id
  })

  await Promise.all(promises)

  return resultingIds
}

const getVectorStoreId = async (uploadedFiles: File[] | null) => {
  try {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY as string,
      dangerouslyAllowBrowser: true
    })

    if (!uploadedFiles) {
      return []
    }

    const vectorStore = await openai.beta.vectorStores.create({
      name: 'Data Documents',
      expires_after: {
        anchor: 'last_active_at',
        days: 1
      }
    })

    await openai.beta.vectorStores.fileBatches.uploadAndPoll(vectorStore.id, {
      files: uploadedFiles
    })

    return vectorStore.id
  } catch (err) {
    return false
  }
}

const updateCollectionNameAndDescription = async ({
  collectionId,
  collectionName,
  collectionDescription,
  collectionShortDescription
}: {
  collectionId: number
  collectionName: string
  collectionDescription: string
  collectionShortDescription: string
}) => {
  const response = fetch(`${URL}/collection/update_collection`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      collectionId,
      collectionName,
      collectionDescription,
      collectionShortDescription
    })
  })
  return response
}

const createNewIndicatorCalculated = async (newCalculatedSettings: {
  title: string
  dataMode: string
  computationMode: string
  noRefresh?: boolean
  base?: string
}) => {
  const res = await fetch(`${URL}/indicator/calculated/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      ...newCalculatedSettings
    })
  })

  const { fid } = await res.json()

  if (newCalculatedSettings.base || newCalculatedSettings.noRefresh) {
    return fid
  }

  if (res.ok) {
    window.switchFunctions.calculated(fid)
  }

  return res.ok
}

const updateBaseScenarioCalculatedIndicator = async ({
  indicatorId,
  baseScenarioId
}: {
  indicatorId: string
  baseScenarioId: string
}) => {
  const res = await fetch(`${URL}/indicator/calculated/update_base`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId,
      baseScenarioId
    })
  })
  return res.ok
}

// const fetchCalculatedIndicator = async (indicatorId: string) => {
//
//   const res = await fetch(`${URL}/indicator/calculated/get`, {
//     method: 'POST',
//     headers: HEADERS,
//     body: JSON.stringify({
//       indicatorId,
//
//     })
//   })

//   const { indicatorData, indicatorParameters, collections, fullDates } =
//     await res.json()

//   return {
//     fetchedIndicatorData: indicatorData,
//     fetchedIndicatorParameters: indicatorParameters,
//     collections,
//     fullDates
//   }
// }

const initialSharing = async ({
  entityId,
  entityType,
  sharingTier,
  initialDescription
}: {
  entityId: string | number
  entityType: string
  sharingTier: number
  initialDescription?: string
}) => {
  const res = await fetch(`${URL}/entity/sharing/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      entityId,
      entityType,
      sharingTier,
      initialDescription
    })
  })

  return res.ok
}

const updateEntitySharing = async ({
  fid,
  sharingTier,
  link,
  description
}: {
  fid: string
  sharingTier: number
  link?: string
  description?: string
}) => {
  const res = await fetch(`${URL}/entity/sharing/update`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      sharingTier,
      link,
      shortDescription: description
    })
  })

  return res.ok
}

const updateEquationCalculatedIndicator = async ({
  indicatorId,
  equation
}: {
  indicatorId: string
  equation: IEquationPiece[]
}) => {
  const res = await fetch(`${URL}/indicator/calculated/update`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId,
      newEquation: equation
    })
  })
  return res.ok
}

const togglePrimarySettingCalculatedIndicator = async ({
  fid,
  base
}: {
  fid: string
  base: string
}) => {
  const res = await fetch(`${URL}/indicator/calculated/variation/primary`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      base
    })
  })

  return res.ok
}

const getCalculatedFromEquation = async (props: IGetCalculatedFromEquation) => {
  const res = await fetch(`${URL}/calculated/data/from_equation`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(props)
  })

  if (res.ok) {
    const json = await res.json()

    return json
  }

  return {}
}

const getCalculatedFromDataAndMode = async (
  props: IGetCalulatedFromDataAndMode
) => {
  const res = await fetch(`${URL}/calculated/data/from_data_and_mode`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(props)
  })

  if (res.ok) {
    const json = await res.json()

    return json
  }

  return {}
}

const updateEntityInverse = async ({
  fid,
  type
}: {
  fid: string
  type: 'calculated' | 'trendline' | 'indicator'
}) => {
  const response = await fetch(`${URL}/entity/flip_inverse/`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      type
    })
  })
  return response.ok
}

const updateScenarioNameAndDescription = async (
  newName: string,
  newDescription: string,
  newShortDescription: string,
  chosenScenario: string
) => {
  const response = fetch(`${URL}/scenario_details`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid: chosenScenario,
      newName,
      newDescription,
      newShortDescription
    })
  })
  return response
}

interface IModifyEntityTags {
  entity: {
    id: string
    type:
      | 'scenario'
      | 'indicator'
      | 'memo'
      | 'collection'
      | 'indicatorGlobal'
      | 'forecast'
      | 'trendline'
      | 'calculated'
  }
  tag: string
  action: 'add' | 'remove'
}

const modifyEntityTags = async ({ entity, tag, action }: IModifyEntityTags) => {
  const response = await fetch(`${URL}/entity/tags`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      entity,
      tag,
      action
    })
  })
  return response.ok
}

const generateNewScenario = async (
  scenarioName: string,
  scenarioOverview: string,
  newScenarioPublic: boolean,
  noRefresh?: boolean
) => {
  const res = await fetch(`${URL}/scenario/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      scenarioName,
      scenarioOverview,
      isPublic: newScenarioPublic
    })
  })

  const { fid } = await res.json()

  if (noRefresh) {
    return fid
  }

  if (res.ok) {
    window.switchFunctions.scenario(fid)
  }

  return res.ok
}

const sendToAWS = async (title: string) => {
  const client = new S3Client({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_AWS_COGNITO_REGION },
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID as string
    })
  })

  const key = `${title}-${uuidv4()}.slice(0, 6)}.png`

  const { url, fields } = await createPresignedPost(client, {
    Bucket: process.env.REACT_APP_AWS_MEMO_BUCKET as string,
    Key: key,
    Conditions: [['content-length-range', 0, 20971520]], // up to 20 MB
    Fields: {
      acl: 'public-read',
      'Content-Type': 'image/png'
    },
    Expires: 120 // 2 minutes
  })

  const link = `https://${process.env.REACT_APP_AWS_MEMO_BUCKET}.s3.${process.env.REACT_APP_AWS_BUCKET_REGION}.amazonaws.com/${key}`

  return {
    url,
    fields,
    link
  }
}

const saveScenarioAsNew = async (
  userId: string,
  scenarioName: string,
  scenarioOverview: string,
  groupId: string,
  oldScenarioId: string,
  newScenarioPublic: boolean
) => {
  const res = await fetch(`${URL}/save_existing_table_as_new`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      scenarioName,
      groupId,
      oldScenarioId,
      scenarioOverview,
      isPublic: newScenarioPublic
    })
  })

  const json = await res.json()

  if (res.ok) {
    window.switchFunctions.scenario(json.fid)
  }

  return res.ok
}

const deleteScenarioById = async (fid: string) => {
  const response = await fetch(`${URL}/scenario/${fid}`, {
    method: 'DELETE',
    headers: HEADERS
  })

  return response.ok
}

const deleteCollectionById = async (collectionId: number) => {
  const response = await fetch(`${URL}/collection/${collectionId}`, {
    method: 'DELETE',
    headers: HEADERS
  })

  return response.ok
}

const createWorkspace = async (workspaceName: string) => {
  const response = await fetch(`${URL}/workspace/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      workspaceName
    })
  })

  if (response.ok) {
    const { fid } = await response.json()
    localStorage.setItem('workspaceFid', fid)
    window.location.href = '/app'
  }
  return response.ok
}

const addToWorkspace = async (fids: (string | number)[]) => {
  const response = await fetch(`${URL}/workspace/add`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fids
    })
  })

  return response.ok
}

const toggleScenarioFollowable = async ({
  fid,
  userId
}: {
  fid: string
  userId: string
}) => {
  try {
    const response = await fetch(`${URL}/scenario/toggle_followable`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        fid
      })
    })
    if (response.ok) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

const getBackendData = async (request: string) => {
  const response = await fetch(`${URL}/api/${request}`, {
    method: 'GET',
    headers: HEADERS
  })

  return response.json()
}

const updateScenarioCoreIndicators = async ({
  fid,
  coreIndicators
}: {
  fid: string
  coreIndicators: string
}) => {
  try {
    const response = await fetch(`${URL}/scenario/core_indicators`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        fid,

        coreIndicators
      })
    })
    if (response.ok) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

const updateTrendlineParameters = async ({
  fid,
  range_complete,
  range_chosen,
  range_chosen_deviation,
  standard_deviation_parameters,
  standard_deviation,
  standard_deviation_relative,
  standard_deviation_chart_mode,
  cellular_calculation_mode_months,
  cellular_calculation_mode,
  relation_scenarios,
  relation_indicators,
  title,
  meaning,
  short_description,
  inverse,
  tags,
  forecast_attached,
  data_attached
}: {
  fid: string
  range_complete?: string
  range_chosen?: string
  range_chosen_deviation?: string
  standard_deviation_parameters?: {
    a: number
    b: number
  }
  inverse?: boolean
  standard_deviation?: number
  standard_deviation_relative?: boolean
  standard_deviation_chart_mode?: string
  cellular_calculation_mode_months?: number
  cellular_calculation_mode?: string
  relation_scenarios?: string
  relation_indicators?: string
  title?: string
  meaning?: string
  short_description?: string
  forecast_attached?: string
  tags?: string
  data_attached?: boolean
}) => {
  try {
    // if there is standard deviation parameters, stringify them
    const response = await fetch(`${URL}/trendline`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        fid,
        range_complete,
        range_chosen,
        range_chosen_deviation,
        standard_deviation_parameters: JSON.stringify(
          standard_deviation_parameters
        ),
        standard_deviation,
        standard_deviation_relative,
        standard_deviation_chart_mode,
        cellular_calculation_mode_months,
        relation_scenarios,
        relation_indicators,
        cellular_calculation_mode,
        inverse,
        title,
        meaning,
        short_description,
        forecast_attached,
        data_attached,
        tags
      })
    })

    return response.ok
  } catch (err) {
    return false
  }
}

const updateForecastParameters = async ({
  fid,
  title,
  meaning,
  short_description,
  inverse,
  tags
}: {
  fid: string
  inverse?: boolean
  title?: string
  meaning?: string
  short_description?: string
  tags?: string
}) => {
  try {
    const response = await fetch(`${URL}/forecast`, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify({
        fid,
        inverse,
        title,
        meaning,
        short_description,
        tags
      })
    })

    return response.ok
  } catch (err) {
    return false
  }
}

const savePDFToDB = async ({
  userId,
  fid,
  pdfData,
  filename
}: {
  userId: string
  fid: string
  pdfData: string
  filename: string
}) => {
  // const response = await fetch(`${URL}/scenario/save_pdf`, {
  //   method: 'POST',
  //   headers: HEADERS,
  //   body: JSON.stringify({
  //     fid,
  //     pdfData,
  //     filename
  //   })
  // })
  // return response.ok
  // need to save on AWS
}

const deleteIndicatorByName = async (indcatorTableName: string) => {
  const response = await fetch(`${URL}/indicator/delete`, {
    method: 'DELETE',
    headers: HEADERS,
    body: JSON.stringify({
      indcatorTableName
    })
  })

  return response.ok
}

const updateRangeTotal = async ({
  rangeTotal,
  entityId,
  entityType
}: {
  rangeTotal: string
  entityId: string
  entityType: 'scenario' | 'indicator'
}) => {
  const response = await fetch(`${URL}/${entityType}/update_range_complete`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      rangeTotal,
      entityId
    })
  })
  return response.ok
}

const updateRangeSelected = async ({
  rangeSelected,
  entityId,
  entityType
}: {
  rangeSelected: string
  entityId: string
  entityType: 'scenario' | 'indicator' | 'calculated_indicator' | 'trendline'
}) => {
  const response = await fetch(`${URL}/${entityType}/update_range_chosen`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      rangeSelected,
      entityId
    })
  })
  return response.ok
}

const updateStandardDeviationParamsIndicator = async ({
  fid,
  parameters
}: {
  fid: string
  parameters: {
    a: number
    b: number
    standardDeviation: string | number
  }
}) => {
  const response = await fetch(`${URL}/indicator/update_std_dev_params`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      parameters
    })
  })
  return response.ok
}

const fetchScenarioData = async (fid: string, userId: string) => {
  try {
    const {
      fetchedScenarioData,
      fetchedAllIndicators,
      fetchedScenarioDetails,
      fetchedSavedComparisonsData
    } = await fetchChosenScenario(fid)

    return {
      fetchedScenarioData,
      fetchedAllIndicators,
      fetchedScenarioDetails,
      fetchedSavedComparisonsData
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getAllChannelContentPieces = async () => {
  const res = await fetch(`${URL}/entity/sharing/get/all`, {
    method: 'GET',
    headers: HEADERS
  })

  return res.json()
}

const getChannelDataById = async (channelId: string) => {
  const res = await fetch(`${URL}/channel?channelId=${channelId}`, {
    method: 'GET',
    headers: HEADERS
  })

  return res.json()
}

const subscribeToChannel = async (channelId: string) => {
  const res = await fetch(`${URL}/channel/subscribe`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      channelId
    })
  })

  return res.ok
}

const unsubscribeFromChannel = async (channelId: string) => {
  const res = await fetch(`${URL}/channel/unsubscribe`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      channelId
    })
  })

  return res.ok
}

const updateIndicarorDetails = async ({
  indicatorId,
  newName,
  newDescription,
  newShortDescription
}: {
  indicatorId: string
  newName: string
  newDescription: string
  newShortDescription: string
}) => {
  const response = await fetch(`${URL}/indicator/update_indicator_details`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId,
      newName,
      newDescription,
      newShortDescription
    })
  })
  return response.ok
}

const fetchedCollectionById = async ({
  collectionId,
  userId
}: {
  collectionId: number
  userId: string
}) => {
  const response = await fetch(`${URL}/collection`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      collectionId
    })
  })
  return response.json()
}

const fetchMemoById = async (fid: string) => {
  const response = await fetch(`${URL}/memo?fid=${fid}`, {
    method: 'GET',
    headers: HEADERS
  })

  return response.json()
}

const createNewMemo = async (title: string) => {
  const response = await fetch(`${URL}/memo`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      title
    })
  })

  const { fid } = await response.json()

  if (response.ok) {
    window.switchFunctions.memo(fid)
  }

  return response.ok
}

const fetchTrendLineById = async (fid: string) => {
  const response = await fetch(`${URL}/trendline`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  const {
    parameters,
    data,
    fullDates,
    allIndicators,
    forecastsAssosiated,
    owner
  } = await response.json()

  return {
    parameters,
    data,
    fullDates: fullDates.map(findClosestMidnightString),
    allIndicators,
    forecastsAssosiated,
    owner
  }
}

const fetchForecastById = async (fid: string) => {
  const response = await fetch(`${URL}/forecast`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  const { parameters, data, allIndicators, fullDates, owner } =
    await response.json()

  return {
    parameters,
    data,
    allIndicators,
    fullDates,
    owner
  }
}

const createNewTrendline = async ({
  indicatorFid,
  sourceInfo,
  params
}: {
  indicatorFid: string
  sourceInfo: {
    source: string
    source_fid?: string
  }
  params?: {
    range_complete: string
    range_chosen: string
    range_chosen_deviation: string
    standard_deviation_parameters: string
    standard_deviation: number
    standard_deviation_relative: boolean
    standard_deviation_chart_mode: string
    meaning: string
    short_description: string
    tags: ITag[]
    type: string
    frequency: string
    data_mode: string
    inverse: boolean
    title?: string
  }
}) => {
  const response = await fetch(`${URL}/trendline/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorFid,
      sourceInfo,
      params: {
        ...params,
        title: params?.title || 'default'
      }
    })
  })

  const { fid: responseFid } = await response.json()

  if (!response.ok || !responseFid) {
    return null
  }

  return responseFid || null
}

const createNewForecast = async (fid: string) => {
  const response = await fetch(`${URL}/forecast/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  const { fid: responseFid } = await response.json()

  if (!response.ok || !responseFid) {
    return null
  }

  return responseFid || null
}

const updateForecastData = async ({
  fid,
  data
}: {
  fid: string
  data: {
    dates: string[]
    data: (string | number | null)[]
  }
}) => {
  const response = await fetch(`${URL}/forecast/data`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      data
    })
  })

  return response.ok
}

const getAllTrendlinesForExternalIndicator = async (indicatorId: string) => {
  const response = await fetch(`${URL}/external/trendlines`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      indicatorId
    })
  })

  return response.json()
}

const updateIndicatorDataMode = async ({
  entityFid,
  entityType,
  newMode
}: {
  entityFid: string
  entityType: string
  newMode: string
}) => {
  const res = await fetch(`${URL}/entity/data_mode`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      entity: {
        entityId: entityFid,
        entityType
      },
      newDataMode: newMode
    })
  })

  return res.ok
}

const generateForecastData = async ({
  forecastId,
  trendlineId,
  dates
}: {
  forecastId: string
  trendlineId: string
  dates: {
    firstDate: string
    lastDate: string
  }
}) => {
  const response = await fetch(`${URL}/forecast/data/generate`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      forecastId,
      trendlineId,
      dates
    })
  })

  return response.ok
}

const switchTrendlineDataMode = async (
  fid: string,
  numberOfMonths?: number,
  state?: boolean
) => {
  const response = await fetch(`${URL}/trendline/data_mode/switch`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      fid,
      numberOfMonths,
      state
    })
  })

  return response.ok
}

const getOpenaiChatResponse = async (messages: IChatMessage[]) => {
  const response = await fetch(`${URL}/openai/chat`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      messages
    })
  })

  return response.json()
}

const getOpenaiAssistantResponse = async ({
  messages,
  chatId,
  vectorStoreId,
  assistantId
  // files,
}: {
  messages: IChatMessage[]
  chatId: string
  // files: string[]
  vectorStoreId?: string
  assistantId: string
}) => {
  const response = await fetch(`${URL}/openai/assistant`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      messages,
      chatId,
      vectorStoreId,
      assistantId
    })
  })

  return response.json()
}

const updateUserSettings = async (newSettings: IUserSettings) => {
  const response = await fetch(`${URL}/user_settings`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      newSettings: {
        ...newSettings,
        data_mapped_all_columns: newSettings.data_mapped_all_columns.join(', '),
        data_mapped_selected_columns:
          newSettings.data_mapped_selected_columns.join(', '),
        homepage_memos:
          newSettings.homepage_memos
            .filter((memo) => !isEmpty(memo))
            .join(', ') || '',
        homepage_scenarios:
          newSettings.homepage_scenarios
            .filter((scenario) => !isEmpty(scenario))
            .join(', ') || '',
        username: newSettings.username,
        profile_image: newSettings.profile_image
      }
    })
  })
  const res = response.ok

  if (res) {
    window.globalSettings = newSettings
    return true
  }

  return false
}

const createInteractionRecord = async (
  entityType: string,
  entityFid: string | null | number
) => {
  const response = await fetch(`${URL}/records`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      entityType,
      entityFid
    })
  })

  return response.ok
}

const updateHomepageSelection = async (newSelected: IHomepageSection[]) => {
  if (!newSelected || !Array.isArray(newSelected) || newSelected.length === 0) {
    return false
  }

  const response = await fetch(`${URL}/homepage/selected`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      newSelected
    })
  })

  return response.ok
}

const deleteTrendlineById = async (fid: string) => {
  const response = await fetch(`${URL}/trendline`, {
    method: 'DELETE',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  return response.ok
}

const deleteForecastById = async (fid: string) => {
  const response = await fetch(`${URL}/forecast`, {
    method: 'DELETE',
    headers: HEADERS,
    body: JSON.stringify({
      fid
    })
  })

  return response.ok
}

const createNewCollection = async ({
  userId,
  collectionName
}: {
  userId: string
  collectionName: string
}) => {
  const response = await fetch(`${URL}/collection/create`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      collectionName
    })
  })
  const { fid } = await response.json()
  if (response.ok) {
    window.switchFunctions.collection(fid)
  }
  return response.ok
}

export {
  updateSelectedColumnsEntity,
  updateEntityTrendlineIncluded,
  createNewIndicator,
  fetchChosenScenario,
  updateScenarioNameAndDescription,
  generateNewScenario,
  saveScenarioAsNew,
  deleteScenarioById,
  cloneIndicator,
  deleteIndicatorByName,
  fetchChosenIndicator,
  updateRangeTotal,
  updateRangeSelected,
  addComparison,
  fetchScenarioData,
  updateIndicarorDetails,
  addGPTChat,
  initialFetch,
  modifyCollectionEntries,
  fetchedCollectionById,
  createNewCollection,
  fetchFullIndicatorsParameters,
  fetchScenarioDetails,
  updateDbIndicator,
  toggleScenarioFollowable,
  checkEntityAccess,
  savePDFToDB,
  getOpenaiAssistantResponse,
  fetchMemoById,
  updateMemo,
  modifyEntityTags,
  handleFormSubmitLogin,
  handleFormSubmitRegister,
  updateStandardDeviationParamsIndicator,
  updateEntityInverse,
  updateScenarioCoreIndicators,
  dummyFunction,
  updateEquationCalculatedIndicator,
  createNewIndicatorCalculated,
  updateIndicatorCalculatedComputationalMode,
  updateBaseScenarioCalculatedIndicator,
  updateTrendlineParameters,
  createNewTrendline,
  deleteTrendlineById,
  deleteCollectionById,
  updateCollectionNameAndDescription,
  updateUserSettings,
  fetchHomepage,
  switchTrendlineDataMode,
  fetchTrendLineById,
  // ADMIN
  runAdminQuery,
  checkIsAdmin,
  updateHomepageSelection,
  updateEntityTrendlineParametersMultiple,
  homepageFetch,
  getMemoLink,
  createNewMemo,
  initialSharing,
  updateEntitySharing,
  getImageFromS3,
  deleteMemo,
  createInteractionRecord,
  sendToAWS,
  createNewForecast,
  updateForecastParameters,
  deleteForecastById,
  fetchForecastById,
  updateIndicatorDataMode,
  updateForecastData,
  getFilesIds,
  getOpenaiChatResponse,
  generateForecastData,
  togglePrimarySettingCalculatedIndicator,
  getVectorStoreId,
  extraHomepageFetch,
  getChannelDataById,
  getAllChannelContentPieces,
  subscribeToChannel,
  getAPIData,
  createWorkspace,
  getAllTrendlinesForExternalIndicator,
  updateEntityIncluded,
  addToWorkspace,
  getBackendData,
  fetchExtraData,
  refreshExternalData,
  getCalculatedFromEquation,
  getCalculatedFromDataAndMode,
  fetchEntityData,
  getAllExternallIndicatorsByName,
  convertExternalToPlatformIndicator,
  unsubscribeFromChannel
  // fetchAllUserIndicators
}
