import React, { useEffect, useRef, useState } from 'react'
import { ILinearChartProps } from '../utils/interfaces'
import {
  FunctionalButton,
  TabbingSwitch
} from '../helperComponents/_components'
import CollapsibleSidePanel from '../helperComponents/CollapsibleSidePanel'
import createHighchartsLinearChart from '../js/HighchartsJS'
import CollapsiblePanelIndicatorsSwitch from './helperComponents/CollapsiblePanelIndicatorsSwitch'

const HighChartsLinearChart = ({
  chartData,
  chartOptions,
  componentOptions,
  page,
  allIndicators
}: ILinearChartProps) => {
  const chartContainerRef = useRef(null)
  const chartInstanceRef = useRef(null)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [scaleType, setScaleType] = useState('linear')

  useEffect(() => {
    if (chartContainerRef.current) {
      if (!chartInstanceRef.current) {
        // Initial chart creation
        const newchart = createHighchartsLinearChart({
          chartData,
          chartOptions,
          componentOptions,
          page,
          container: chartContainerRef.current,
          scaleType
        })

        chartInstanceRef.current = newchart as any
      } else {
        // Update the chart with new data or options
        ;(chartInstanceRef.current as any).updateChart()
      }
    }

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (
        chartInstanceRef.current &&
        (chartInstanceRef.current as any).chartInstance
      ) {
        ;(chartInstanceRef.current as any).chartInstance.destroy()
        chartInstanceRef.current = null
      }
    }
  }, [chartData, chartOptions, componentOptions, scaleType])

  return (
    <div className="chart-container">
      <div className="entity-parameters">
        {page === 'indicator' && (
          <FunctionalButton
            className="teriary icon center inline"
            noReturn
            functionToExecute={() => {
              setIsPanelOpen(!isPanelOpen)
            }}
            doesReset
            initialButtonState={'Series Controls'}
            iconSettings
          />
        )}
        <TabbingSwitch
          numberVisible={3}
          className="justify-content-end"
          options={[
            {
              label: 'Absolute',
              active: scaleType === 'linear',
              onClick: () => setScaleType('linear')
            },
            {
              label: 'Relative to First',
              active: scaleType === 'relative',
              onClick: () => setScaleType('relative')
            },
            {
              label: 'Logarithmic',
              active: scaleType === 'logarithmic',
              onClick: () => setScaleType('logarithmic')
            }
          ]}
        />
      </div>

      <div ref={chartContainerRef}></div>
      {page === 'indicator' && allIndicators && (
        <CollapsibleSidePanel
          isOpen={isPanelOpen}
          onToggle={() => setIsPanelOpen(!isPanelOpen)}
          functionalButton={undefined}
          position="left full"
        >
          <CollapsiblePanelIndicatorsSwitch
            allIndicators={allIndicators}
            chartInstanceRef={chartInstanceRef}
            indicators={chartData.filteredKeys}
          />
        </CollapsibleSidePanel>
      )}
    </div>
  )
}

export { HighChartsLinearChart }
